import {Root, StyledDropdown, StyledLabel} from './dropdown.styles'

export const Dropdown = ({options,setValue}: {options:string[],setValue: (value:string) => void}) => {
	 const onChange = (event:any) => {
		setValue(event.target.value);
	}
	
	return (
	<Root>
	<StyledLabel>Size</StyledLabel>
	<StyledDropdown onChange={onChange}>
		{options.map((option: string) => <option key={option} value={option}>{option}</option>)}
	</StyledDropdown>
	</Root>)
}