import { ProductCard } from "../../fragments/product-card";
import { Header, Root, Footer } from "./product-info.styles";

export const ProductInfo = () => (
  <Root>
    <Header>10000shirts</Header>
    <ProductCard />
    <Footer>An experiment made by two 🇸🇪</Footer>
  </Root>
);
