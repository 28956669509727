import { useState } from "react"
import { Button } from "../../components";
import { Dropdown } from "../../components/dropdown";
import { Root, Image, Title } from "./product-card.styles";

const availableSizes = ["S", 'M', 'L', 'XL', 'XXL']

export const ProductCard = () => {
	const [size, setSize] = useState(availableSizes[0]);
	console.log({size})
	return (<Root>
		<Image src={`${process.env.PUBLIC_URL}/qr-product-image.png`}/>
		<Title>One unique shirt</Title>
		<Dropdown options={availableSizes}  setValue={setSize}/>
		<Button text="Checkout" onClick={() => console.log("Checkout")}/>
	</Root>)

}