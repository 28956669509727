import styled from "styled-components";
import { CUSTOM_BLUE, CUSTOM_PINK } from "../../constants";

export const StyledButton = styled.button`
  height: 64px;
  width: 192px;
  /* background-color: transparent; */
  border: 1px solid ${CUSTOM_PINK};
  /* color: ${CUSTOM_PINK}; */
  transition: 0.3s;
  border-radius: 16px;
  font-size: 24px;
  font-weight: bold;

  background-color: ${CUSTOM_PINK};
  color: ${CUSTOM_BLUE};

  /* &:hover,
  &:focus {
		cursor: pointer;
		background-color: ${CUSTOM_PINK};
		color: ${CUSTOM_BLUE};
        
    } */
`;
