import styled from "styled-components";
import {CUSTOM_LIGHT_GRAY} from '../../constants'

export const Root = styled.div`
  display: flex;
  flex: 1;
  height:100vh;
  @media screen and (max-width:600px){
  height: -webkit-fill-available;}
  justify-content:space-between;
  align-items:center;
  flex-direction:column;
`;

export const ProductSection = styled.div`
  display: flex;
`

export const Header = styled.h1`
  font-family: futura;
  color: ${CUSTOM_LIGHT_GRAY};
  font-size: 120px;
  @media screen and (max-width:600px){font-size:48px;}
`

export const Footer = styled.h4`
color: ${CUSTOM_LIGHT_GRAY};
`