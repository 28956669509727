import styled from "styled-components";
import { CUSTOM_LIGHT_GRAY } from "../../constants";

export const Root = styled.div`
  display: flex;
  margin-bottom: 16px;
  & > *  {
    margin-right: 8px;
  }
`;

export const StyledDropdown = styled.select`
`;

export const StyledLabel = styled.label`
color: ${CUSTOM_LIGHT_GRAY};`;
