import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ComingSoon, ProductInfo } from "./screens";

export const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/" element={<ProductInfo />} />
    </Routes>
  </BrowserRouter>
);

export default App;
