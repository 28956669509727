import styled from "styled-components";
import { CUSTOM_PINK } from "../../constants";

export const Root = styled.div`
  display: flex;
  flex: 1;
  /* height: 100vh; */
  /* width: 100vw; */
  align-items: center;
  justify-content: center;
`;

export const Header = styled.h1`
  color: ${CUSTOM_PINK};
`;
