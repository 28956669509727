import styled from 'styled-components';
import {CUSTOM_LIGHT_GRAY} from "../../constants"

export const Root = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
align-items: center;

`

export const Title = styled.h2`
	color: ${CUSTOM_LIGHT_GRAY};

`

export const Image = styled.img`
	max-width: 300px;
	max-height: 400px;
`
