import { createGlobalStyle } from "styled-components";
import { CUSTOM_BLUE } from "./constants";

export const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		box-sizing: border-box;
		background-color: ${CUSTOM_BLUE};
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
			'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
			sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
`;
